<template>
  <form class="account-form">
    <Transition name="fade" appear>
      <div v-if="success" class="success-message">
        {{ $t("success") }}
      </div>
    </Transition>
    <div class="form-input">
      <label for="login">{{ $t("personPage.login") }}</label>
      <PrimaryInput
        iconPath="M16.9993 32.5837C8.39291 32.5837 1.41602 25.6068 1.41602 17.0003C1.41602 8.39389 8.39291 1.41699 16.9993 1.41699C25.6058 1.41699 32.5827 8.39389 32.5827 17.0003C32.5827 25.6068 25.6058 32.5837 16.9993 32.5837ZM27.482 24.2602C28.9116 22.1999 29.7493 19.698 29.7493 17.0003C29.7493 9.95869 24.041 4.25033 16.9993 4.25033C9.95772 4.25033 4.24935 9.95869 4.24935 17.0003C4.24935 19.698 5.08714 22.1999 6.51673 24.2602C8.11112 22.1543 11.8577 21.2503 16.9993 21.2503C22.141 21.2503 25.8876 22.1543 27.482 24.2602ZM25.4493 26.5483C25.0347 25.0574 22.056 24.0837 16.9993 24.0837C11.9427 24.0837 8.96402 25.0574 8.54938 26.5483C10.799 28.5407 13.7579 29.7503 16.9993 29.7503C20.2408 29.7503 23.1997 28.5407 25.4493 26.5483ZM16.9993 21.2503C13.8272 21.2503 11.3327 19.0224 11.3327 14.167C11.3327 10.9877 13.5697 8.50033 16.9993 8.50033C20.4195 8.50033 22.666 11.2226 22.666 14.4503C22.666 19.0964 20.1416 21.2503 16.9993 21.2503ZM14.166 14.167C14.166 17.3818 15.3251 18.417 16.9993 18.417C18.6678 18.417 19.8327 17.423 19.8327 14.4503C19.8327 12.6801 18.7215 11.3337 16.9993 11.3337C15.2055 11.3337 14.166 12.4894 14.166 14.167Z"
      >
        <input type="text" id="login" :value="userData?.id_doc" disabled />
      </PrimaryInput>
    </div>
    <div class="form-input">
      <label for="email">{{ $t("personPage.email") }}</label>
      <PrimaryInput
        iconPath="M21.5742 9.91634H24.4076V16.9997C24.4076 20.5002 24.676 21.2497 25.8242 21.2497C27.672 21.2497 28.6576 20.3758 28.6576 16.9997C28.6576 9.50643 24.8992 5.66634 17.3244 5.66634C12.417 5.66696 8.06753 8.82588 6.54885 13.4924C5.03017 18.1589 6.68736 23.2726 10.6544 26.1614C14.6215 29.0501 19.997 29.0575 23.972 26.1797L25.6336 28.4747C20.6649 32.072 13.9454 32.0627 8.98657 28.4518C4.02774 24.8409 1.95626 18.4487 3.85461 12.6155C5.75296 6.78243 11.1898 2.83378 17.3242 2.83301C26.4778 2.83301 31.4909 7.95511 31.4909 16.9997C31.4909 22.0216 29.166 24.083 25.8242 24.083C23.9204 24.083 22.8309 23.4692 22.2327 22.1066C20.9596 23.3305 19.2298 24.083 17.3242 24.083C13.4122 24.083 10.2409 20.9117 10.2409 16.9997C10.2409 13.0877 13.4122 9.91634 17.3242 9.91634C18.9188 9.91634 20.3904 10.4433 21.5742 11.3325V9.91634ZM21.5742 16.9997C21.5742 19.3469 19.6714 21.2497 17.3242 21.2497C14.977 21.2497 13.0742 19.3469 13.0742 16.9997C13.0742 14.6525 14.977 12.7497 17.3242 12.7497C19.6714 12.7497 21.5742 14.6525 21.5742 16.9997Z"
        :errText="!editDisabled && error.email ? ' ' : ''"
        :success="!editDisabled && !error.email"
      >
        <input
          type="text"
          id="email"
          @blur="verifyEmail"
          v-model="changes.email"
          :disabled="editDisabled"
        />
      </PrimaryInput>
    </div>
    <div class="form-input">
      <label for="name">{{ $t("personPage.name") }}</label>
      <PrimaryInput
        iconPath="M23.2869 2.83301C24.2144 2.83301 25.1036 3.20335 25.753 3.85786L30.1459 8.2508C30.8 8.90484 31.1674 9.79191 31.1674 10.7169C31.1674 11.6418 30.8 12.5289 30.1459 13.1829L14.1071 29.2167C13.1176 30.3581 11.7149 31.0594 10.108 31.1696H2.83398V29.753L2.83859 23.7817C2.95927 22.2879 3.65376 20.8988 4.71319 19.9654L20.8191 3.85956C21.4722 3.20252 22.3604 2.83301 23.2869 2.83301ZM10.008 28.3398C10.7648 28.2863 11.4693 27.934 12.0349 27.287L22.7476 16.5743L17.4289 11.2554L6.65346 22.0283C6.0795 22.5359 5.72442 23.2461 5.66732 23.8958V28.3372L10.008 28.3398ZM19.4327 9.25214L24.7511 14.5708L28.1424 11.1795C28.2651 11.0568 28.3341 10.8904 28.3341 10.7169C28.3341 10.5434 28.2651 10.377 28.1424 10.2543L23.7456 5.85739C23.6243 5.73511 23.4591 5.66634 23.2869 5.66634C23.1147 5.66634 22.9495 5.73511 22.8282 5.85739L19.4327 9.25214Z"
      >
        <input type="text" id="name" :value="userData?.first_name" disabled />
      </PrimaryInput>
    </div>
    <div class="form-input">
      <label for="last_name">{{ $t("personPage.lanstName") }}</label>
      <PrimaryInput
        iconPath="M23.2869 2.83301C24.2144 2.83301 25.1036 3.20335 25.753 3.85786L30.1459 8.2508C30.8 8.90484 31.1674 9.79191 31.1674 10.7169C31.1674 11.6418 30.8 12.5289 30.1459 13.1829L14.1071 29.2167C13.1176 30.3581 11.7149 31.0594 10.108 31.1696H2.83398V29.753L2.83859 23.7817C2.95927 22.2879 3.65376 20.8988 4.71319 19.9654L20.8191 3.85956C21.4722 3.20252 22.3604 2.83301 23.2869 2.83301ZM10.008 28.3398C10.7648 28.2863 11.4693 27.934 12.0349 27.287L22.7476 16.5743L17.4289 11.2554L6.65346 22.0283C6.0795 22.5359 5.72442 23.2461 5.66732 23.8958V28.3372L10.008 28.3398ZM19.4327 9.25214L24.7511 14.5708L28.1424 11.1795C28.2651 11.0568 28.3341 10.8904 28.3341 10.7169C28.3341 10.5434 28.2651 10.377 28.1424 10.2543L23.7456 5.85739C23.6243 5.73511 23.4591 5.66634 23.2869 5.66634C23.1147 5.66634 22.9495 5.73511 22.8282 5.85739L19.4327 9.25214Z"
        :errText="!editDisabled && error.last_name ? ' ' : ''"
        :success="!editDisabled && !error.last_name"
      >
        <input
          type="text"
          id="last_name"
          v-model="changes.last_name"
          :disabled="editDisabled"
        />
      </PrimaryInput>
    </div>
    <div class="form-input">
      <label for="pinfl">{{ $t("NewRegister.pinfl") }}</label>
      <PrimaryInput
        iconPath="M29.75 31.1663H8.5C6.15279 31.1663 4.25 29.2635 4.25 26.9163V7.08301C4.25 4.7358 6.15279 2.83301 8.5 2.83301H29.75V25.4997C28.9676 25.4997 28.3333 26.1339 28.3333 26.9163C28.3333 27.6987 28.9676 28.333 29.75 28.333V31.1663ZM25.5 26.9163C25.5 26.4196 25.5852 25.9428 25.7418 25.4997H8.5C7.7176 25.4997 7.08333 26.1339 7.08333 26.9163C7.08333 27.6987 7.7176 28.333 8.5 28.333H25.7418C25.5852 27.8899 25.5 27.4131 25.5 26.9163ZM8.5 5.66634H26.9167V22.6663H8.5C8.00327 22.6663 7.52644 22.7516 7.08333 22.9082V7.08301C7.08333 6.3006 7.7176 5.66634 8.5 5.66634Z"
      >
        <input type="text" id="pinfl" :value="userData?.pinfl" disabled />
      </PrimaryInput>
    </div>
    <div class="form-input">
      <label for="birth_date">{{ $t("personPage.born") }}</label>
      <PrimaryInput
        iconPath="M11.6582 8.49967H8.82487V7.08301H5.99154V11.333H28.6582V7.08301H25.8249V8.49967H22.9915V7.08301H11.6582V8.49967ZM28.6582 14.1663H5.99154V28.333H28.6582V14.1663ZM22.9915 4.24967H11.6582V2.83301H8.82487V4.24967H5.99154C4.42673 4.24967 3.1582 5.5182 3.1582 7.08301V28.333C3.1582 29.8978 4.42673 31.1663 5.99154 31.1663H28.6582C30.223 31.1663 31.4915 29.8978 31.4915 28.333V7.08301C31.4915 5.5182 30.223 4.24967 28.6582 4.24967H25.8249V2.83301H22.9915V4.24967ZM10.2415 19.833V16.9997H13.0749V19.833H10.2415ZM15.9082 19.833H18.7415V16.9997H15.9082V19.833ZM21.5749 19.833V16.9997H24.4082V19.833H21.5749ZM10.2415 22.6663V25.4997H13.0749V22.6663H10.2415ZM18.7415 25.4997H15.9082V22.6663H18.7415V25.4997Z"
        :errText="!editDisabled && error.birth_date ? ' ' : ''"
        :success="!editDisabled && !error.birth_date"
      >
        <input
          type="date"
          id="birth_date"
          placeholder="Дата рождения"
          v-model="changes.birth_date"
          :disabled="editDisabled"
        />
      </PrimaryInput>
    </div>
    <div class="form-input">
      <label for="phone">{{ $t("personPage.phone") }}</label>
      <PrimaryInput
        iconPath="M24.4082 1.41699H10.2415C8.67673 1.41699 7.4082 2.68552 7.4082 4.25033V29.7503C7.4082 31.3151 8.67673 32.5837 10.2415 32.5837H24.4082C25.973 32.5837 27.2415 31.3151 27.2415 29.7503V4.25033C27.2415 2.68552 25.973 1.41699 24.4082 1.41699ZM10.2415 4.25033H14.4915C14.4915 5.03273 15.1258 5.66699 15.9082 5.66699H18.7415C19.5239 5.66699 20.1582 5.03273 20.1582 4.25033H24.4082V29.7503H10.2415V4.25033Z"
      >
        <input
          type="text"
          id="phone"
          maxlength="13"
          v-model="changes.phone"
          disabled
        />
      </PrimaryInput>
    </div>
    <div class="form-input">
      <label for="password">{{ $t("authPage.password") }}</label>
      <PrimaryInput
        iconPath="M10.2402 9.91634V14.1663H8.82357C7.31007 14.1663 5.99023 15.2662 5.99023 16.7636V28.5691C5.99023 30.0665 7.31007 31.1663 8.82357 31.1663H25.8236C27.3371 31.1663 28.6569 30.0665 28.6569 28.5691V16.7636C28.6569 15.2662 27.3371 14.1663 25.8236 14.1663H24.4069V9.91634C24.4069 6.00432 21.2356 2.83301 17.3236 2.83301C13.4116 2.83301 10.2402 6.00432 10.2402 9.91634ZM21.5736 9.91634V14.1663H13.0736V9.91634C13.0736 7.56913 14.9764 5.66634 17.3236 5.66634C19.6708 5.66634 21.5736 7.56913 21.5736 9.91634ZM8.82357 28.333V16.9997H25.8236V28.333H8.82357ZM18.7402 22.6663C18.7402 23.4487 18.106 24.083 17.3236 24.083C16.5412 24.083 15.9069 23.4487 15.9069 22.6663C15.9069 21.8839 16.5412 21.2497 17.3236 21.2497C18.106 21.2497 18.7402 21.8839 18.7402 22.6663Z"
        :success="!editDisabled && !error.password"
        :errText="error.password ? $t('NewRegister.moreSymbol') : ''"
      >
        <input
          v-model="changes.password"
          type="current-password"
          placeholder="******"
          @blur="
            error.password =
              changes.password.length != undefined &&
              changes.password.length < 6
          "
          :disabled="editDisabled"
        />
      </PrimaryInput>
    </div>
    <div class="form-img">
      <div class="img-item">
        <span> {{ $t("personPage.facePhoto") }} </span>
        <div class="img-wrap" v-if="!images.face">
          <img :src="changes.face" alt="face image" />
        </div>
        <LoadFile
          class="button-change"
          :size="0.2"
          v-if="!editDisabled"
          :isFace="true"
          @getImg="setImg('face', $event)"
          idInput="file1"
          :isNoSlot="false"
          >{{ $t("changeButton") }}</LoadFile
        >
      </div>
      <div v-if="userData?.document" class="img-item">
        <span> {{ $t("personPage.documentPhoto") }} </span>
        <div class="img-wrap" v-if="!images.document">
          <img :src="changes.document" alt="scan passport" />
        </div>
        <LoadFile
          class="button-change"
          :size="2"
          v-if="!editDisabled"
          :isFace="false"
          @getImg="setImg('document', $event)"
          idInput="file2"
          :isNoSlot="false"
          >{{ $t("changeButton") }}</LoadFile
        >
      </div>
      <div
        v-if="userData?.metrika && userData?.is_korean == true"
        class="img-item"
      >
        <span> {{ $t("personPage.metrikaPhoto") }} </span>
        <div class="img-wrap">
          <img :src="changes.metrika" alt="scan metrik" />
        </div>
        <LoadFile
          class="button-change"
          v-if="!editDisabled"
          :size="2"
          :isFace="false"
          @getImg="setImg('metrika', $event)"
          idInput="file3"
          :isNoSlot="false"
          >{{ $t("changeButton") }}</LoadFile
        >
      </div>
    </div>
    <PrimaryButton
      type="button"
      @click="updateUser"
      :title="$t('personPage.save')"
      v-if="!editDisabled"
    />
    <PrimaryButton
      :title="editDisabled ? $t('personPage.change') : $t('personPage.cancel')"
      @click="editDisabled ? (editDisabled = !editDisabled) : cancel()"
      type="button"
      class="change-button"
      isOutlined
    />
  </form>
</template>

<script setup>
import { ref, reactive, computed, onMounted } from "vue";
import PrimaryInput from "@/components/PrimaryInput";
import PrimaryButton from "@/components/PrimaryButton";
import { useStore } from "vuex";
import { useLanguage } from "@/utils/language";
import { useRouter } from "vue-router";
import LoadFile from "@/components/register/LoadFile.vue";

const editDisabled = ref(true);
const store = useStore();
const { i18nRoute } = useLanguage();
const router = useRouter();
const loading = ref(true);
const success = ref(false);

const error = reactive({
  email: false,
  phone: false,
  first_name: false,
  last_name: false,
  password: false,
});

const errorMSg = reactive({
  first_name: "",
  email: "",
  birth_date: "",
  phone: "",
});

const images = reactive({
  face: false,
  document: false,
  metrika: false,
});

store.dispatch("period/getRegisterPeriod", {});

function validateEmail(elementValue) {
  /* eslint-disable*/
  const emailRe =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  /* eslint-enable */
  return emailRe.test(elementValue);
}

const verifyEmail = () => {
  error.email = !validateEmail(changes.email);
};

const setImg = (foto, event) => {
  images[foto] = true;
  changes[foto] = event;
};

const updateUser = () => {
  const formData = new FormData();

  for (const name in changes) {
    if (
      name == "blocked_until" ||
      name == "block_reason" ||
      name == "phone" ||
      images[name] == false ||
      name == "first_name" ||
      (name == "password" &&
        (changes.password == undefined || changes.password.length == 0))
    ) {
      console.log(name);
    } else {
      if (!error.password) {
        if (changes[name] !== null) {
          formData.append(name, changes[name]);
          error.password = false;
          error[name] = false;
        } else {
          error[name] = true;
        }
      } else {
        error.password = true;
      }
    }
  }

  if (changes.phone.length == 13) {
    error.phone = false;
    if (!/(^[0-9]+$)/.test(changes.phone.replace("+", ""))) {
      error.phone = true;
    }
  } else {
    error.phone = true;
  }

  if (Object.values(error).indexOf(true) == -1) {
    store.dispatch("auth/updateUser", {
      cb: () => {
        editDisabled.value = true;
        success.value = true;
        changes.password = "";
        images.document = false;
        images.face = false;

        setTimeout(() => {
          success.value = false;
        }, 5000);
      },
      formData: formData,
      err: (error) => {
        const keysObject = Object.keys(error)[0];
        errorMSg[keysObject] = error[keysObject];
      },
    });
  }
};

onMounted(() => {
  if (store.getters["auth/getAccessToken"]) {
    store.dispatch("auth/getMe", {
      cb: () => {
        loading.value = false;
      },
    });
  } else {
    router.push(
      i18nRoute({
        name: "Home",
      })
    );
  }
});

const userData = computed(() => store.getters["auth/getUser"]);

const cancel = () => {
  window.location.reload();
};

const changes = reactive({
  email: computed({
    get() {
      return store.getters["auth/getUser"]?.email;
    },
    set(value) {
      const updateEmail = value;
      store.commit("auth/setUpdateUser", {
        data: updateEmail,
        str: "email",
      });
    },
  }),
  phone: computed({
    get() {
      return store.getters["auth/getUser"]?.phone;
    },
    set(value) {
      const updatePhone = value;
      store.commit("auth/setUpdateUser", {
        data: updatePhone,
        str: "phone",
      });
    },
  }),
  last_name: computed({
    get() {
      return store.getters["auth/getUser"]?.last_name;
    },
    set(value) {
      const updateVal = value.replace(/[^a-zA-Z]+/giu, "").toUpperCase();
      store.commit("auth/setUpdateUser", {
        data: updateVal,
        str: "last_name",
      });
    },
  }),
  birth_date: computed({
    get() {
      return store.getters["auth/getUser"]?.birth_date;
    },
    set(value) {
      store.commit("auth/setUpdateUser", {
        data: value,
        str: "birth_date",
      });
    },
  }),
  face: computed({
    get() {
      return store.getters["auth/getUser"]?.face;
    },
    set(value) {
      store.commit("auth/setUpdateUser", {
        data: value,
        str: "face",
      });
    },
  }),
  document: computed({
    get() {
      return store.getters["auth/getUser"]?.document;
    },
    set(value) {
      store.commit("auth/setUpdateUser", {
        data: value,
        str: "document",
      });
    },
  }),
  metrika: computed({
    get() {
      return store.getters["auth/getUser"]?.metrika;
    },
    set(value) {
      store.commit("auth/setUpdateUser", {
        data: value,
        str: "metrika",
      });
    },
  }),
  password: computed({
    get() {
      return store.getters["auth/getUser"]?.password;
    },
    set(value) {
      store.commit("auth/setUpdateUser", {
        data: value,
        str: "password",
      });
    },
  }),
});
</script>

<style lang="scss" scoped>
.account {
  padding: 3.125rem 0;

  .success-message {
    position: fixed;
    top: 1.25rem;
    right: 1.25rem;
    font-size: 1.25rem;
    color: var(--white);
    z-index: 10;
    background: var(--success-color);
    border-radius: 0.63rem;
    padding: 1.25rem 1.88rem;
  }

  &-tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2.5rem;

    .tab-item {
      color: var(--disabled-color);
      margin: 0 1.25rem;
      padding-bottom: 0.125rem;
      border-bottom: 0.25rem solid transparent;
      cursor: pointer;

      &.active {
        color: var(--dark-blue);
        border-color: var(--primary-blue);
      }
    }
  }

  &-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 3.75rem;
    grid-row-gap: 1.875rem;

    .change-button {
      grid-column: 2 / 3;
    }

    .form-input {
      label {
        font-weight: 400;
        font-size: 1.25em;
        color: var(--text-gray);
        line-height: 190%;
        margin-bottom: 0.3125rem;
      }
    }

    .form-img {
      display: flex;
      grid-column: 1 / 3;
      grid-row: 5 / 7;

      .img-item {
        display: flex;
        flex-direction: column;
        max-width: 12.5rem;

        &:not(:last-child) {
          margin-right: 2.9375rem;
        }

        span {
          font-weight: 400;
          font-size: 1.25em;
          line-height: 160%;
          margin-bottom: 0.31rem;
          color: var(--text-gray);
        }

        .img-wrap {
          border: 0.125rem solid #bec1ce;
          border-radius: 0.625rem;
          overflow: hidden;
          width: 12.1875rem;
          height: 16rem;
          margin-bottom: 0.63rem;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .account {
    padding: 2.125rem 0;

    &-form {
      font-size: 11.5px;
      .form-img {
        .img-item {
          &:not(:last-child) {
            margin-right: 1.4375rem;
          }
          .img-wrap {
            // width: 9.375rem;
            // height: 13rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .account {
    &-form {
      grid-template-columns: 100%;
      max-width: 35rem;
      margin: 0 auto;
      .change-button {
        grid-column: auto;
      }

      .form-img {
        grid-column: auto;
        grid-row: auto;
        flex-wrap: wrap;
        gap: 1.25rem;

        .img-item {
          &:not(:last-child) {
            margin: 0;
          }
          justify-content: flex-start;
        }
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .account {
    &-tabs {
      width: 100%;
      font-size: 1.125rem;
      justify-content: space-between;
      align-items: center;

      .tab-item {
        margin: 0 0.3125rem;
        text-align: center;
      }
    }

    &-form {
      grid-template-columns: 100%;
      max-width: 20rem;
      margin: 0 auto;

      .change-button {
        grid-column: auto;
      }

      .form-img {
        grid-column: auto;
        grid-row: auto;

        .img-item {
          .img-wrap {
            width: 100%;
            height: max-content;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}
</style>
